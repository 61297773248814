
.breadcrumbs-container {
	@include make-md-column(6);
	
	.breadcrumb { 
		background-color: transparent; 
		text-align: right;

		li:first-child {
			font-family: "FontAwesome", sans-serif;
		}
	}
}

.divider-container {
	margin-bottom: 20px;
}

/*------------------------------------*\
    TABLES
\*------------------------------------*/

.table {

	td, th {
		vertical-align: middle !important;
	}
}