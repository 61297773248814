/*====================================*\
    created by ELJOTStudio
\*=====================================

    Elżbieta Milwicz & Jacek Bandura
    
    |---------------------|
    | www.eljot-strony.pl |
    |---------------------|

======================================*/

/*------------------------------------*\
    CONTENTS
\*------------------------------------

    - LINKS
    - LISTS
    - ALIGNMENTS

*-------------------------------------*/



/*------------------------------------*\
     LINKS
\*------------------------------------*/

.spaced-link {
    margin: 2em 0;
    font-size: 14px;
}





/*------------------------------------*\
  LISTS
\*------------------------------------*/

.nav-list {
    list-style-type:none;
    margin-left: 0;
    padding-left: 0;


    li {
        display:inline;
    }
}

.no-bullets {
    list-style-type:none;
    margin-left: 0;
    padding-left: 0;

}





/*------------------------------------*\
    ALIGNMENTS etc.
\*------------------------------------*/

.push--bottom {
	margin-bottom: 1em;
}

.push--top {
	margin-top: 1em;
}

.push--top--double {
    margin-top: 2em;
}

.text-left { text-align: left; }
.text-right { text-align: right; }

.divider { margin: 0 1em; }

.divider--half { margin: 0 0.5em; }

.divider--left--half { margin-left: 0.5em; }
.divider--right--half { margin-right: 0.5em; }

.divider--right { margin-right: 1em; }
.divider--left { margin-left: 1em; }


