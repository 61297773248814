$brand-primary: #d16316;
$breadcrumb-separator: "::";

/*------------------------------------*\
    NAVBAR
\*------------------------------------*/

$navbar-default-bg: $brand-primary;
$navbar-default-color: white;

$navbar-default-link-color:                white;
$navbar-default-link-hover-color:          lighten($brand-primary, 40%);
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         lighten($brand-primary, 40%);
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       darken($brand-primary, 20%);
$navbar-default-link-disabled-bg:          transparent !default;